import React, {createRef} from 'react';
import Layout from 'components/common/layout/layout';
import {Secction1, Secction2, Secction3} from 'components/home/Content/occss-home';
import LinkArrow from "components/ui/atoms/LinkArrow";
import theme from "styles/style";
import Contacto from "components/common/contacto/Contacto";
import team from "components/home/Content/assets/team.jpg";
import teamWebp from "components/home/Content/assets/team.webp";
import Ruben from "assets/images/ruben.jpg";
import Luis from "assets/images/luis.jpg";
import Jorge from "assets/images/jorge.jpg";
import RubenWebp from "assets/images/ruben.webp";
import LuisWebp from "assets/images/luis.webp";
import JorgeWebp from "assets/images/jorge.webp";
import {graphql} from "gatsby";
import Hero from "../components/common/cover/hero";
import { SEO } from 'components/common/SEO';
import HomeMarkupSchema from "../components/common/SEO/HomeMarkupSchema";


const QuienesSomos = ({data}) => {
    
    const start = createRef(null);
    const block_1 = createRef(null);
    const block_2 = createRef(null);
    const block_3 = createRef(null);
    const end = createRef(null);

    const adjust = {
        start: { left: 80, color: `${theme.orange}`, size: 400, animation: false, center: false},
        block_1: { left: 10, color: `${theme.blue}`, size: 200, animation: true, center: false},
        block_2: { left: 50, color: `${theme.blue}`, size: 800, animation: true, center: false},    
        block_3: { left: 80, color: `${theme.blue}`, size: 200, animation: true, center: false},
    };    

    return (
        <>
        <SEO
              title = "Quienes somos Obtura"
              description= "Conoce al equipo que hay detrás de las cámaras de Obtura. Somos gente joven y maja. Entra a saludar"
              canonical = 'quienes-somos'
              robots = 'index, follow'
          />
                  <HomeMarkupSchema />
        <Layout start={start} block_1={block_1} block_2={block_2} block_3={block_3} end={end} adjust={adjust} >
            <Hero to={"#Quienes-somos"} src={data.sanityHeroPages.codeVideo__landscape} vsrc={data.sanityHeroPages.codeVideo__portrait} title={"Portada"} />
            <div className={"container"}  ref={start}></div>
            <Secction1 className="container Home__S1 padding-xl" id={"Quienes-somos"}>
                <div >
                    <h1 className={"HomeS1__m-title putDot--orange"}> QUIÉNES SOMOS <br/>OBTURA</h1>
                    <div className={"HomeS1__m-text"} >
                    <p>
                        Si vas a trabajar con nosotros, o al menos lo estás pensando, es importante que nos presentemos: somos Luis, Rubén y Pablo. Los tres formamos Obtura Creación Audiovisual, una idea que vio la luz con una motivación compartida por los tres: revolucionar nuestro sector favorito. 
                    </p>
                    </div>
                </div>
                <div className={"HomeS1__m-picture"}>
                    <picture alt="Foto del equipo">
                        <source srcSet={teamWebp}  width={"611"} loading={"lazy"}  />
                        <img src={team}  width={"611"} loading={"lazy"}  />
                    </picture>
                </div>
                <div className={"a-linkContact"}>
                    <LinkArrow to={"/contacto/"} text={"CONTÁCTANOS"} color={theme.orange} />
                </div>
            </Secction1>
            <div className={"container"}  ref={block_1}></div>
            <Secction2 className="container Home__S2 padding-xl">
            <div className={"HomeS2__m-title"}>
                <h2 className={"h1 "}> IMAGINAMOS<span className="orange" >.</span> CREAMOS
                <span className="blue" >.</span><br/> PRODUCIMOS<span className="green" >.</span> </h2>
            </div>
            <div className={"HomeS2__m-text"}>
                <p >
                Somos técnicos superiores en realización audiovisual , nos conocimos como estudiantes y hoy continuamos de la mano. Cada uno de nosotros se especializó en un campo diferente: Rubén, por ejemplo, está titulado en pilotaje de drones y es el responsable de las grabaciones subacuáticas por su experiencia en buceo. 
            </p><p>
                Por otro lado, a Luis le encanta el manejo tanto de cámara de fotos como de vídeo. ¿Y Pablo? Pues es el genio de la edición, paso clave antes de la entrega de un trabajo. 
            </p>
            </div>
        </Secction2>
        <div className={"slider__container__items o-team padding-xl"}  ref={block_2}>
            <div className={"card__about"} >
                <picture alt="Foto de Ruben">
                    <source alt="Foto de Ruben" srcSet={RubenWebp} loading={"lazy" }  />
                    <img alt="Foto de Ruben" src={Ruben} className={"a-team-image"} />
                </picture>
                <div class="card__about__detail">
                    <h3 className={"card__about__title"}> Rubén Molledo </h3>
                </div>
            </div>
            <div className={"card__about"} >
                <picture alt="Foto de Luis">
                    <source alt="Foto de Luis" srcSet={LuisWebp} loading={"lazy"}  />
                    <img alt="Foto de Luis" src={Luis}  alt="Foto de Luis" className={"a-team-image"} />
                </picture>
                <div class="card__about__detail">
                    <h3 className={"card__about__title"}> Luis Cuartero </h3>
                </div>
            </div>
            <div className={"card__about"} >
                <picture alt="Foto de Pablo">
                    <source alt="Foto de Pablo" srcSet={JorgeWebp} loading={"lazy"}  />
                    <img alt="Foto de Pablo" src={Jorge} className={"a-team-image"} />
                </picture>
                <div class="card__about__detail">
                    <h3 className={"card__about__title"}> Pablo Antonio Borque </h3>
                </div>
            </div>
        </div>
        <div className="container" ref={block_3}> </div>
        <Secction3 className="container Home__S3 padding-xl">
            <div>
                <h2 className={"h1 HomeS3__m-title putDot--blue"}> ¿QUIERES QUE HABLEMOS? </h2>
            </div>
        <div className={"HomeS3__m-text"}>
            <p>
                Uniendo las habilidades de cada uno nació Obtura. Y con ella pretendemos romper con los clásicos a través de proyectos, creativos, únicos y profesionales. Siempre de la mano de nuestros clientes, por supuesto.
            </p><p>
              Si estás valorando contratar una productora audiovisual y quieres conocernos un poco más, solo tienes que tomar nota de nuestros datos de contacto. Los encontrarás al final de la página o puedes hacer clic en "contáctanos" y dejarnos un formulario. 
            </p>
        </div>
        </Secction3>
        <div className={"container padding-xl"} id={"contactar"} ref={end}>
                <Contacto />
            </div>
        </Layout>
        </>
    )
}

export const query = graphql`
  query QuienesSomosPageQuery {
    sanityHeroPages(pages: {eq: "quienes-somos"}) {
        codeVideo__landscape
        codeVideo__portrait
    }
  }
`

export default QuienesSomos
